export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDO1sZHktoR44lVqKqNl1kLvGzeiTB1yTk',
    authDomain: 'revels-dev.firebaseapp.com',
    projectId: 'revels-dev',
    storageBucket: 'revels-dev.appspot.com',
    messagingSenderId: '816988751802',
    appId: '1:816988751802:web:572eac9cc577f1f4477f83'
  },
  name: 'Development'
};
